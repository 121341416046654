.btn {
    --bs-btn-hover-border-color: #254c65;
}

.btn:focus-visible {
    box-shadow: none;
}

/* so wären die Buttons gleichartig */
/* .input-group-ih .btn-new-folder-ih {
    background: #fefefe !important;
    border: 2px dotted transparent;
    border-radius: 8px !important;
    margin: 0 20px !important;
    color: #5ab7da;
}
.input-group-ih .btn-new-folder-ih:hover {
    background: none !important;
    color: #fefefe;
    border-color: #fefefe;
} */

#service-navigation {
    padding: 10px 0;
    position: relative;
    background: #fefefe;
    top: -85px !important;
    border-top: 1px solid #e8e8e8;
    margin: 0px 0px 0px 0px;
}

#service-navigation .container-fluid {
    margin: 0px 10px 0px 0px !important;
}

#navbarNavDropdown .navbar-item {
    margin-left: 10px;
}

@media screen and (max-width: 800px) {
    #navbarNavDropdown {
        justify-content: flex-start !important;
    }

    #navbarNavDropdown .navbar-item {
        margin-left: 0px !important;
        margin-right: 6px !important;
    }
}

#service-navigation svg {
    width: 20px;
}
